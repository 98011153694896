import React from "react"

// @Material UI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Skeleton from "@material-ui/lab/Skeleton"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

// @animations
import Lottie from "react-lottie"
import animationData from "../../lotties/notFound"

// @Firebase
import firebase from "gatsby-plugin-firebase"

// @Local
import SEO from "../seo"
import TopButtons from "../singles/topButtons"

const lottieNotFound = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  card: {
    textAlign: "center",
    height: "100%",
  },
  cardWrapper: {
    height: "100%",
    justifyContent: "center",
    display: "flex",
  },
  cardContent: {
    textAlign: "left",
    paddingLeft: 20,
  },
  name: {
    fontSize: 15,
    fontWeight: 500,
    color: "#5b5656",
    textTransform: "uppercase",
  },
  email: {
    fontSize: 12,
    color: "#5b5656",
    fontWeight: 300,
  },
  ocupation: {
    fontSize: 12,
    color: "#5b5656",
    fontWeight: 100,
  },
  phone: {
    fontSize: 12,
    color: "#d5392d",
    fontWeight: 300,
  },
  wrapperFilters: {
    justifyContent: "center",
  },
  activeFilter: {
    backgroundColor: "#365eab",
    color: "#FFF",
  },
  avatar: {
    width: 70,
    height: 70,
    margin: "0 auto",
    border: "solid",
    borderWidth: 3,
    borderColor: "#365eab",
  },
})

const SkeletonItems = () => {
  const classes = useStyles()
  let items = []
  for (let i = 1; i <= 4; i++) {
    items.push(
      <Grid item xs={12} sm={6}>
        <Card className={classes.card}>
          <CardContent className={classes.cardWrapper}>
            <Grid container alignItems={"center"}>
              <Grid item xs={3}>
                <Skeleton variant="circle" width={70} height={70} />
              </Grid>
              <Grid item xs={9}>
                <Skeleton variant="rect" width={210} height={20} border={5} />
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={150} />
                <Skeleton variant="text" width={80} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }
  return items
}

const UserCardA = ({ data }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} sm={6}>
      <Card className={classes.card}>
        <CardContent className={classes.cardWrapper}>
          <Grid container alignItems={"center"}>
            <Grid item xs={3}>
              {data._photoURL ? (
                <Avatar
                  sizes={"large"}
                  alt={data.name}
                  src={data._photoURL}
                  className={classes.avatar}
                />
              ) : (
                <Avatar alt={data.name} className={classes.avatar}>
                  {data.name.charAt(0) + data.lastname.charAt(0)}
                </Avatar>
              )}
            </Grid>
            <Grid item xs={9} className={classes.cardContent}>
              <Typography className={classes.name} color="textSecondary">
                {data.name} {data.lastname}
              </Typography>
              <Typography className={classes.ocupation} color="textSecondary">
                {data.ocupation} | {data.group}
              </Typography>
              <Typography color="textSecondary">
                <a href={`mailto:${data.email}`} className={classes.email}>
                  {data.email}
                </a>
              </Typography>
              <Typography className={classes.phone} color="textSecondary">
                {data.phone}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

const DirectoryContent = () => {
  const classes = useStyles()
  const [data, setData] = React.useState([])
  const [keyword, setKeyword] = React.useState("@a")
  const [searchArray, setSearchArray] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [authSession, setAuthSession] = React.useState(false)

  React.useEffect(() => {
    if (!authSession || !authSession.uid) {
      firebase.auth().onAuthStateChanged(user => {
        setAuthSession(firebase.auth().currentUser)
      })
    }
  }, [authSession])

  React.useEffect(() => {
    setLoading(true)
    if (authSession) {
      firebase
        .firestore()
        .collection("DTVecolDirectory")
        .where("active", "==", "1")
        .orderBy("name")
        .where("_fullText", "array-contains", keyword)
        .get()
        .then(snapshot => {
          if (snapshot.size > 0) {
            setData(snapshot.docs)
            var temp = searchArray
            temp[keyword] = snapshot.docs
            setSearchArray(temp)
          }
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [authSession])

  const PrepareAlphabets = () => {
    let result = []
    for (let i = 65; i < 91; i++) {
      result.push(
        <Grid item key={i}>
          <Button
            className={
              "@" + String.fromCharCode(i).toLowerCase() === keyword
                ? classes.activeFilter
                : null
            }
            key={i}
            variant="outlined"
            color="primary"
            onClick={() => {
              reloadSearch("@" + String.fromCharCode(i).toLowerCase())
            }}
          >
            {String.fromCharCode(i)}
          </Button>
        </Grid>
      )
    }
    return result
  }

  const reloadSearch = async search => {
    if (keyword !== search && authSession) {
      setKeyword(search)
      if (searchArray[search] && searchArray[search].length > 0) {
        setData(searchArray[search])
        setLoading(false)
      } else {
        setLoading(true)
        await firebase
          .firestore()
          .collection("DTVecolDirectory")
          .where("active", "==", "1")
          .orderBy("name")
          .where("_fullText", "array-contains", search)
          .get()
          .then(snapshot => {
            if (snapshot.size > 0) {
              setData(snapshot.docs)
              var temp = searchArray
              temp[search] = snapshot.docs
              setSearchArray(temp)
            } else {
              setData([])
            }
            setLoading(false)
          })
      }
    }
  }

  if (loading) {
    return (
      <Grid container xs={12} spacing={2} alignItems={"stretch"}>
        <Grid item xs={12}>
          <Grid
            container
            xs={12}
            spacing={1}
            alignItems={"stretch"}
            className={classes.wrapperFilters}
          >
            <PrepareAlphabets />
          </Grid>
        </Grid>
        <SkeletonItems />
      </Grid>
    )
  }

  return (
    <Grid container xs={12} spacing={2} alignItems={"stretch"}>
      <Grid item xs={12}>
        <Grid
          container
          xs={12}
          spacing={1}
          alignItems={"stretch"}
          className={classes.wrapperFilters}
        >
          <PrepareAlphabets />
        </Grid>
      </Grid>
      {data && data.length > 0 ? (
        data.map((snapshot, index) => {
          return <UserCardA key={index} data={snapshot.data()} />
        })
      ) : (
        <Grid item xs={12} alignContent={"center"}>
          <Lottie options={lottieNotFound} height={400} width={400} />
        </Grid>
      )}
    </Grid>
  )
}

const Page = () => {
  return (
    <Grid xs={12}>
      <SEO title="Directorio" />
      <Grid container spacing={3} className="page-detail basic-detail">
        <Grid item xs={12} sm={12} lg={7} className="page-detail-content">
          <Typography
            className="page-title"
            variant="h1"
            color="textPrimary"
            align="center"
          >
            DIRECTORIO
          </Typography>
          <DirectoryContent />
        </Grid>
        <Grid item xs={12} sm={12} lg={5} className="page-detail-sidebar">
          <div style={{ height: 100 }} />
          <TopButtons />
          <div style={{ height: 50 }} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Page
