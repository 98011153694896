import React from "react"
import clsx from "clsx"

// @Material UI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Link from "@material-ui/core/Link"
import Tooltip from "@material-ui/core/Tooltip"
import FolderIcon from "@material-ui/icons/Folder"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import HomeIcon from "@material-ui/icons/Home"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import DescriptionIcon from "@material-ui/icons/Description"
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto"

// @Local
import { isLoggedIn, getUserExtra } from "../../utils/auth"
import SEO from "../seo"
import TopButtons from "../singles/topButtons"
import { Card } from "@material-ui/core"

// @animations
import Lottie from "react-lottie"
import animationLoader from "../../lotties/vecolLoader"
import animationCloud from "../../lotties/DriveCloud.json"

// Client ID and API key from the Developer Console
var CLIENT_ID =
  "257774746154-8fjlr1cvf20mg9u6f0of93178kqdr3cr.apps.googleusercontent.com"
var API_KEY = "AIzaSyC4MslEYRYJVSqjIUTa7JCw61p22taEXCc"
// Array of API discovery doc URLs for APIs used by the quickstart
var DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
]
// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
var SCOPES =
  "https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive.metadata.readonly"

const lottieLoader = {
  loop: true,
  autoplay: true,
  animationData: animationLoader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}
const lottieDriveCloud = {
  loop: true,
  autoplay: true,
  animationData: animationCloud,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const useStyles = makeStyles({
  blockTitle: {
    fontWeight: 400,
    fontSize: 20,
    color: "rgb(95, 99, 104)",
  },
  driveHeading: {
    padding: 20,
    // backgroundColor: 'blue'
  },
  driveContainer: {
    padding: 20,
    // backgroundColor: 'red'
  },
  driveContainerDocs: {
    padding: 20,
    // backgroundColor: 'green'
  },
  btn: {
    margin: "10px 0",
  },
  wrapperCard: {
    height: "100%",
  },
  wrapperCardClick: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#365eab",
      "& svg, & p": {
        color: "#FFF",
        fontWeight: "bold",
      },
    },
  },
  wrapperFolder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 0",
  },
  iconFolder: {
    fontSize: 30,
    color: "rgb(95, 99, 104)",
  },
  textFolder: {
    fontSize: 12,
    color: "rgba(0,0,0,.72)",
    marginLeft: 20,
  },
  breadcrumbWrapper: {
    padding: 10,
    margin: "10px 0",
    background: "#FFF",
    borderRadius: 5,
    width: "100%",
  },
  breadcrumb: {
    fontSize: 12,
  },
  activeBread: {
    color: "#365eab",
    fontWeight: "bold",
  },
  docIcon: {
    textAlign: "center",
  },
  iconDoc: {
    fontSize: 55,
    color: "rgb(95, 99, 104)",
  },
  docDownload: {
    textAlign: "center",
  },
  downloadLink: {
    display: "inline-flex",
    height: 35,
    width: 35,
    borderRadius: 50,
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    backgroundColor: "#eaeaea",
  },
  downloadIcon: {
    fontSize: 20,
  },
  docName: {
    textAlign: "center",
    padding: 5,
    marginTop: 10,
    "& p": {
      fontSize: 12,
      color: "rgb(95, 99, 104)",
      margin: 0,
    },
  },
  iconPdf: {
    color: "#F40F02",
  },
  iconWord: {
    color: "#00A4EF",
  },
  iconImg: {
    // color: 'yellow'
  },
})

const Drive = () => {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [loggedIn, setLoggedIn] = React.useState(false)
  const [driveItems, setDriveItems] = React.useState([])
  // const [rootId, setRootId] = React.useState(null);
  const [activeFolderId, setActiveFolderId] = React.useState(null)
  const [breadCrumbs, setBreadCrumbs] = React.useState([])
  const [breadCrumbsNames, setBreadCrumbsNames] = React.useState([])
  const [emptyFolder, setEmptyFolder] = React.useState(false)

  React.useEffect(() => {
    var script = document.createElement("script")
    script.onload = handleClientLoad
    script.src = "https://apis.google.com/js/api.js"
    document.body.appendChild(script)
  }, [])

  const handleClientLoad = () => {
    window.gapi.load("client:auth2", initClient)
  }

  const initClient = () => {
    window.gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(
        function () {
          window.gapi.auth2
            .getAuthInstance()
            .isSignedIn.listen(updateSigninStatus)
          updateSigninStatus(
            window.gapi.auth2.getAuthInstance().isSignedIn.get()
          )
        },
        function (error) {
          // ERROR
          // JSON.stringify(error, null, 2)
        }
      )
  }

  const updateSigninStatus = isSignedIn => {
    if (isSignedIn) {
      setLoggedIn(true)
      getRootFolder()
    } else {
      setLoggedIn(false)
    }
  }

  const handleAuthClick = event => {
    window.gapi.auth2.getAuthInstance().signIn()
  }

  const handleSignoutClick = event => {
    setEmptyFolder(false)
    window.gapi.auth2.getAuthInstance().signOut()
  }

  const getRootFolder = () => {
    if (getUserExtra() && getUserExtra().cod_interno) {
      var code_int = getUserExtra().cod_interno
      window.gapi.client.drive.files
        .list({
          fields:
            "files(id, name, mimeType, webContentLink, md5Checksum, fullFileExtension, size, webViewLink)",
          q: "mimeType='application/vnd.google-apps.folder'",
          q: `name='VECOL_${code_int}'`,
        })
        .then(function (response) {
          var files = response.result.files
          if (files && files[0] && files[0].id) {
            retrieveAllFilesInFolder(files[0].id, "root")
            // setRootId(files[0].id)
          } else {
            setEmptyFolder(true)
          }
        })
    }
  }

  const retrieveAllFilesInFolder = (folderId, folderName) => {
    setLoading(true)
    setActiveFolderId(folderId)

    // Validate Breadcrumbs
    var tempBreadCrumbs = breadCrumbs.concat(folderId)
    var tempBreadCrumbsNames = breadCrumbsNames.concat(folderName)
    const index = tempBreadCrumbs.indexOf(folderId)
    if (index > -1) {
      tempBreadCrumbs.splice(index + 1)
      setBreadCrumbs(tempBreadCrumbs)
      tempBreadCrumbsNames.splice(index + 1)
      setBreadCrumbsNames(tempBreadCrumbsNames)
    }

    window.gapi.client.drive.files
      .list({
        fields:
          "files(id, name, mimeType, webContentLink, md5Checksum, fullFileExtension, size, webViewLink)",
        q: `'${folderId}' in parents`,
      })
      .then(function (response) {
        appendDrive(response.result.files ? response.result.files : null)
      })
  }

  const appendDrive = items => {
    setDriveItems(items)
    setLoading(false)
  }

  const PrintBreadCrumbs = () => {
    if (breadCrumbs.length <= 1) {
      return null
    }

    return (
      <Grid container xs={12}>
        <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.breadcrumbWrapper}
        >
          {breadCrumbs.map((breadId, index) => {
            return (
              <Link
                className={clsx(
                  classes.breadcrumb,
                  activeFolderId === breadId ? classes.activeBread : null
                )}
                color="inherit"
                href="/"
                onClick={event => {
                  event.preventDefault()
                  retrieveAllFilesInFolder(breadId, breadCrumbsNames[index])
                }}
              >
                {index === 0 ? <HomeIcon /> : null}
                {index !== 0 ? breadCrumbsNames[index] : null}
              </Link>
            )
          })}
        </Breadcrumbs>
      </Grid>
    )
  }

  if (loading) {
    return (
      <div>
        <Grid container xs={12} spacing={2} className={classes.driveHeading}>
          <Grid item xs={4}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="100"
              viewBox="0 0 1443.061 1249.993"
            >
              <path
                fill="#3777e3"
                d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"
              />
              <path
                fill="#ffcf63"
                d="M962.055 833.329h481.006L962.055 0H481.017z"
              />
              <path
                fill="#11a861"
                d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"
              />
            </svg>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.blockTitle}>
              Encuentra fácilmente desprendibles de pagos de nómina, primas,
              rentas y otros documentos relacionados.
            </Typography>
            {!loggedIn ? (
              <Button
                variant="outlined"
                color="primary"
                size={"small"}
                onClick={handleAuthClick}
                className={classes.btn}
              >
                Sincronizar carpeta nómina
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                size={"small"}
                onClick={handleSignoutClick}
                className={classes.btn}
              >
                Quitar carpeta nómina
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Lottie options={lottieLoader} height={200} width={200} />
        </Grid>
      </div>
    )
  }

  return (
    <div>
      <Grid container xs={12} spacing={2} className={classes.driveHeading}>
        <Grid item xs={4}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="100"
            viewBox="0 0 1443.061 1249.993"
          >
            <path
              fill="#3777e3"
              d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"
            />
            <path
              fill="#ffcf63"
              d="M962.055 833.329h481.006L962.055 0H481.017z"
            />
            <path
              fill="#11a861"
              d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"
            />
          </svg>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.blockTitle}>
            Encuentra fácilmente desprendibles de pagos de nómina, primas,
            rentas y otros documentos relacionados.
          </Typography>
          {!loggedIn ? (
            <Button
              variant="outlined"
              color="primary"
              size={"small"}
              onClick={handleAuthClick}
              className={classes.btn}
            >
              Sincronizar carpeta nómina
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              size={"small"}
              onClick={handleSignoutClick}
              className={classes.btn}
            >
              Quitar carpeta nómina
            </Button>
          )}
        </Grid>
      </Grid>
      <PrintBreadCrumbs />
      <Grid container xs={12} spacing={2} className={classes.driveContainer}>
        {loggedIn && driveItems.length > 0 && (
          <Grid item xs={12}>
            <Typography className={classes.blockTitle}>Carpetas</Typography>
          </Grid>
        )}
        {loggedIn && driveItems.length > 0
          ? driveItems.map((item, index) => {
              return item.mimeType === "application/vnd.google-apps.folder" ? (
                <Folder
                  data={item}
                  onClick={() => {
                    retrieveAllFilesInFolder(item.id, item.name)
                  }}
                />
              ) : null
            })
          : null}
      </Grid>
      <hr />
      <Grid
        container
        xs={12}
        spacing={2}
        className={classes.driveContainerDocs}
      >
        {loggedIn && driveItems.length > 0 && (
          <Grid item xs={12}>
            <Typography className={classes.blockTitle}>Archivos</Typography>
          </Grid>
        )}
        {loggedIn && driveItems.length > 0
          ? driveItems.map((item, index) => {
              return item.mimeType !== "application/vnd.google-apps.folder" ? (
                <Doc data={item} />
              ) : null
            })
          : null}
      </Grid>
      <Grid item xs={12} className={classes.driveEmpty}>
        {emptyFolder ? (
          <div>
            <Lottie options={lottieDriveCloud} height={100} width={150} />
            <Typography
              className={classes.blockTitle}
              style={{ padding: "5%", textAlign: "justify" }}
            >
              Por el momento tu carpeta nómina no existe o no tiene ningún
              contenido disponible. Comunícate con recursos humanos para más
              información
            </Typography>
          </div>
        ) : null}
      </Grid>
    </div>
  )
}

const Folder = ({ data, onClick }) => {
  const classes = useStyles()
  return (
    <Grid item xs={6} sm={4} lg={3}>
      <Card
        className={clsx(classes.wrapperCard, classes.wrapperCardClick)}
        onClick={onClick}
      >
        <div className={classes.wrapperFolder}>
          <FolderIcon className={classes.iconFolder} />
          <Typography className={classes.textFolder}>{data.name}</Typography>
        </div>
      </Card>
    </Grid>
  )
}

const Doc = ({ data }) => {
  const classes = useStyles()

  const IconFile = ({ className, mimeType }) => {
    if (mimeType.includes("pdf")) {
      return <PictureAsPdfIcon className={clsx(className, classes.iconPdf)} />
    }
    if (mimeType.includes("word")) {
      return <DescriptionIcon className={clsx(className, classes.iconWord)} />
    }
    if (mimeType.includes("image")) {
      return <InsertPhotoIcon className={clsx(className, classes.iconImg)} />
    }
    return <InsertDriveFileIcon className={className} />
  }

  return (
    <Grid item xs={6} sm={4} lg={3}>
      <Tooltip title={data.name} arrow>
        <Card className={classes.wrapperCard}>
          <Grid container xs={12} className={classes.wrapperFolder}>
            <Grid item xs={6} alignItems={"center"} className={classes.docIcon}>
              <IconFile className={classes.iconDoc} mimeType={data.mimeType} />
            </Grid>
            <Grid
              item
              xs={6}
              alignItems={"center"}
              className={classes.docDownload}
            >
              <a
                href={data.webViewLink}
                target="_blank"
                rel="noreferrer"
                className={classes.downloadLink}
              >
                <CloudDownloadIcon className={classes.downloadIcon} />
              </a>
            </Grid>
            <Grid item xs={12} className={classes.docName}>
              <Typography className={classes.textFolder}>
                {data.name}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Tooltip>
    </Grid>
  )
}

const Page = () => {
  if (!isLoggedIn) {
    return <div />
  }

  return (
    <Grid xs={12}>
      <SEO title="Carpeta nómina" />
      <Grid container spacing={3} className="page-detail basic-detail">
        <Grid item xs={12} sm={12} lg={7} className="page-detail-content">
          <Typography
            className="page-title"
            variant="h1"
            color="textPrimary"
            align="center"
          >
            Carpeta nómina
          </Typography>
          <Drive />
        </Grid>
        <Grid item xs={12} sm={12} lg={5} className="page-detail-sidebar">
          <div style={{ height: 100 }} />
          <TopButtons />
          <div style={{ height: 50 }} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Page
