import React from "react"
import { navigate } from "@reach/router"

// @MaterialUI
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import Divider from "@material-ui/core/Divider"
import Link from "@material-ui/core/Link"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"

// @Firebase
import firebase from "gatsby-plugin-firebase"
import { logout, setUser, isLoggedIn, getUser } from "../utils/auth"

// @Local
import SEO from "../components/seo"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://vecol.com.co/">
        Vecol
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export function SignInSide() {
  const classes = useStyles()
  const user = getUser()
  const [loading, setLoading] = React.useState(false)
  const [userLocal, setUserLocal] = React.useState(null)
  const [authSession, setAuthSession] = React.useState(false)
  const [accessDenied, setAccessDenied] = React.useState(false)

  React.useEffect(() => {
    if (userLocal && userLocal.email) {
      firebase
        .firestore()
        .collection("DTVecolDirectory")
        .doc(userLocal.email)
        .get()
        .then(snapshot => {
          if (snapshot && snapshot.data() && snapshot.data().email) {
            setAuthSession(snapshot.data())
            setAccessDenied(false)
          } else {
            setAccessDenied(true)
          }
        })
    } else if (user && user.email) {
      navigate("/app/home")
    }
  }, [userLocal])

  const loginPressed = async () => {
    setLoading(true)
    firebase.auth().languageCode = "es"
    var provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope("profile")
    provider.addScope("email")
    provider.setCustomParameters({
      scope: ["profile", "email"],
      hd: "vecol.com.co",
    })
    await firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        if (result.user) {
          setLoading(false)
          setUser(result.user)
          setUserLocal(result.user)
        }
      })
      .catch(function (error) {
        var errorCode = error.code
        var errorMessage = error.message
        console.log(`2. code:${errorCode} [[ ${errorMessage} ]]`)
        setLoading(false)
      })
  }

  return (
    <div style={{ textAlign: "center" }}>
      <img
        alt={"Vecol"}
        src={require("../images/logo200Alone.png")}
        className={classes.logo}
      />
      {accessDenied ? (
        <Typography component="h3" variant="h5" className={classes.title}>
          Acceso denegado. El email usado no corresponde a Vecol.
        </Typography>
      ) : (
        <Typography component="h3" variant="h5" className={classes.title}>
          {authSession ? "Ingresar a la Intranet" : "Acceso Intranet"}
        </Typography>
      )}
      {authSession && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate("/app/home")}
          size={"large"}
          className={classes.button}
          endIcon={<ArrowForwardIosIcon />}
          style={{ margin: "10px 0" }}
        >
          Ingresar
        </Button>
      )}
      <Divider style={{ marginTop: 30, marginBottom: 30 }} />
      {authSession && authSession._photoURL && (
        <Avatar
          alt={authSession.name}
          src={authSession._photoURL}
          className={classes.logo}
        />
      )}
      <Typography component="h3" variant="h5" className={classes.title}>
        {authSession ? authSession.name : null}
      </Typography>
      {!authSession && !loading && (
        <Button
          className={[classes.btn]}
          onClick={loginPressed}
          variant="contained"
          size={"large"}
          color="primary"
        >
          <img
            alt={"Google"}
            src={require("../images/google-icon.svg")}
            className={classes.loginIcon}
          />
          Iniciar sesión
        </Button>
      )}
      {authSession && authSession.email && !loading && (
        <Button
          variant="outlined"
          color="secondary"
          className={classes.button}
          onClick={event => {
            event.preventDefault()
            logout(firebase).then(() => navigate(`/app/login`))
          }}
          size={"large"}
          endIcon={<ExitToAppIcon />}
        >
          Cerrar sesión
        </Button>
      )}
      {loading && (
        <Button
          className={classes.btn}
          onClick={null}
          variant="contained"
          color="primary"
        >
          <CircularProgress className={classes.loader} size={25} />
        </Button>
      )}
    </div>
  )
}

export default function Page() {
  const classes = useStyles()

  if (isLoggedIn()) {
    navigate(`/app/home`)
  }

  return (
    <div>
      <SEO title="Inicio de sesión" />
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <SignInSide />
          </div>
          <Copyright />
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${require("../images/LoginBACK.jpg")})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    margin: "20px 0",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: 120,
    height: 120,
    borderRadius: "10%",
    textAlign: "center",
    margin: "0 auto",
  },
  imgProfile: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    textAlign: "center",
    margin: "0 auto",
    marginTop: 10,
    marginBottom: 10,
  },
  loader: {
    color: "#FFF",
  },
  btn: {
    width: 230,
    height: 48,
    margin: "10px 0",
    backgroundColor: "#4285F4",
    "&:hover": {
      backgroundColor: "#FFF",
      color: "#4285F4",
    },
  },
  btnLogout: {
    backgroundColor: theme.palette.secondary.main,
  },
  loginIcon: {
    width: 40,
    height: 40,
    backgroundColor: "#FFF",
    padding: 5,
    borderRadius: "10%",
    marginRight: 10,
  },
}))
