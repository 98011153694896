import React from "react"
import clsx from "clsx"

// @MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"

// @LocalComponents
import SEO from "../seo"
import WelcomeMessage from "../singles/welcomeMessage"
import TopButtons from "../singles/topButtons"
import BlockButtons from "../singles/blockButtons"
import BannerButtons from "../singles/bannerButtons"

export default function Dashboard() {
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)
  return (
    <Grid container xs={12} spacing={3}>
      <SEO title={"Inicio"} />
      <Grid item xs={12} md={3} lg={3}>
        <Paper
          className={clsx(
            fixedHeightPaper,
            "paperWrapperWelcome",
            classes.paperWrapperWelcome
          )}
        >
          <WelcomeMessage />
        </Paper>
      </Grid>
      {/* Buttons */}
      <Grid item xs={12} md={3} lg={5}>
        <TopButtons />
      </Grid>
      {/* News */}
      <Grid item xs={12} md={6} lg={4}>
        <BlockButtons />
      </Grid>
      {/* Banner Buttons */}
      <Grid item xs={12} md={12} lg={12}>
        <BannerButtons />
      </Grid>
    </Grid>
  )
}

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  headingWrapper: {
    alignItems: "center",
    "& h2": {
      margin: 0,
    },
    "& .MuiButtonBase-root": {
      marginLeft: 22,
      fontSize: 12,
      height: 20,
    },
  },
  paperWrapperWelcome: {
    backgroundColor: "#365eab",
  },
}))
