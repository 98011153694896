import React, { Component } from "react"

// @Material UI
import { makeStyles } from "@material-ui/core/styles"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"

export const SuccessSimple = props => {
  const classes = useStyles()
  let { bgColor, warning } = props
  return (
    <div
      className={classes.cardSuccessWrapper}
      style={bgColor ? { backgroundColor: bgColor } : null}
    >
      <div className={classes.simpleHeadingWrapper}>
        <div className={classes.simpleHeading}>
          {props.heading ? props.heading : "Confirmado"}
        </div>
        {warning ? (
          <ErrorOutlineIcon className={classes.simpleHeadingIcon} />
        ) : (
          <CheckCircleOutlineIcon className={classes.simpleHeadingIcon} />
        )}
      </div>
      {props.text ? (
        <div style={{}}>
          <p className={classes.simpleHeadingText}>{props.text}</p>
        </div>
      ) : null}
      {props.children}
    </div>
  )
}

export default class Messages extends Component {
  render() {
    return <div>Messages</div>
  }
}

const useStyles = makeStyles(theme => ({
  cardSuccessWrapper: {
    display: "block",
    justifyContent: "center",
    alignSelf: "center",
    backgroundColor: "#21bf73",
    borderRadius: 10,
    padding: 20,
    margin: "0px auto",
    width: "100%",
    maxWidth: 400,
  },
  simpleHeadingWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  simpleHeading: {
    fontWeight: "900",
    fontSize: 18,
    color: "#FFF",
  },
  simpleHeadingText: {
    fontWeight: "400",
    fontSize: 15,
    color: "#FFF",
  },
  simpleHeadingIcon: {
    fontSize: 30,
    marginLeft: 10,
    color: "#FFF",
  },
}))
