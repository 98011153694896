import React, { useState, useEffect } from "react"
import clsx from "clsx"

// @Material UI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import GetAppIcon from "@material-ui/icons/GetApp"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import LinkOffIcon from "@material-ui/icons/LinkOff"
import IconButton from "@material-ui/core/IconButton"

// @Local
import { isLoggedIn, getUser } from "../../utils/auth"
import { getDateFormat } from "../../utils/date"

// @Firebase
import firebase from "gatsby-plugin-firebase"

const Item = ({ data }) => {
  const styles = stl()
  // const navigation = useNavigation();

  let { _created, _updated, response, _state, text } = data
  var date = _created.toDate()

  if (_state != "NOT-PROCESS" && _updated) {
    date = _updated.toDate()
  }

  // DATE FORMAT
  date.toLocaleString("es-CO", { timeZone: "America/Bogota" })
  let formatDate = getDateFormat(date)

  function viewPDF() {
    // @todo: Validate WebView feature.
    if (response && response.url && response.url !== null) {
      window.open(response.url, "_blank")
    }
  }

  async function downloadPDF() {
    if (response && response.url && response.url !== null) {
      window.open(response.url)
    }
  }

  if (_state == "NOT-PROCESS") {
    return (
      <Grid
        item
        xs={6}
        md={4}
        lg={3}
        className={styles.container}
        style={{ backgroundColor: "#52057b" }}
      >
        <div className={styles.left}>
          <p className={styles.date} style={{ color: "#FFFFFF" }}>
            Fecha solicitud: {formatDate}
          </p>
          <p className={styles.notice}>{`Desprendible Nómina (${text})`}</p>
        </div>
        <div className={styles.right}>
          <ErrorOutlineIcon className={styles.iconWarning} />
          <p className={styles.loading} style={{ color: "#FFFFFF" }}>
            Solicitando
          </p>
        </div>
      </Grid>
    )
  }

  if (response && response.error && response.error === true && !response.url) {
    return (
      <Grid
        item
        xs={6}
        md={4}
        lg={3}
        className={styles.container}
        style={{ backgroundColor: "#aa3a3a" }}
      >
        <div className={styles.left}>
          <p className={styles.date} style={{ color: "#FFFFFF" }}>
            Fecha solicitud: {formatDate}
          </p>
          <p className={styles.notice}>{`Desprendible Nómina (${text})`}</p>
        </div>
        <div className={styles.right}>
          <ErrorOutlineIcon className={styles.iconWarning} />
          <p className={styles.loading} style={{ color: "#FFFFFF" }}>
            {response.message
              ? response.message
              : "Solicitud NO exitosa. Comunicarse con el departamento de TI"}
          </p>
        </div>
      </Grid>
    )
  }

  return (
    <Grid
      item
      xs={6}
      md={4}
      lg={3}
      className={styles.container}
      style={{ backgroundColor: "#158467" }}
    >
      <div className={styles.left}>
        <p className={styles.date} style={{ color: "#FFFFFF" }}>
          Fecha solicitud: {formatDate}
        </p>
        <p className={styles.notice}>{`Desprendible Nómina (${text})`}</p>
      </div>
      {response && response.url ? (
        <div className={clsx(styles.actions)}>
          <div className={styles.actionItem}>
            <IconButton onClick={() => viewPDF()} aria-label="ver-pdf">
              <PictureAsPdfIcon className={clsx(styles.icon, styles.pdf)} />
            </IconButton>
          </div>
        </div>
      ) : (
        <div className={styles.right}>
          <LinkOffIcon
            className={styles.iconWarning}
            style={{ fontSize: 35 }}
          />
        </div>
      )}
    </Grid>
  )
}

const History = () => {
  const [data, setData] = useState(null)
  const [authSession, setAuthSession] = useState(false)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setAuthSession(firebase.auth().currentUser)
    })
  })

  useEffect(() => {
    firebase
      .firestore()
      .collection("SpecialActionsCertificates")
      .where("user_uid", "==", getUser().uid)
      .where("type", "==", "nCert")
      // .where("_created", "<", new Date())
      // .orderBy("_created", "desc")
      .onSnapshot(querySnapshot => {
        if (querySnapshot && querySnapshot.docs) {
          setData(querySnapshot)
        }
      })
  }, [])

  if (
    !data ||
    !data.docs ||
    data.docs.length <= 0 ||
    !getUser() ||
    getUser() == null
  ) {
    return <div />
  }

  return (
    <div style={{ margin: "2rem 0" }}>
      <Typography
        gutterBottom
        variant="h5"
        component="h5"
        align={"center"}
        style={{ margin: "3rem 0" }}
      >
        Histórico
      </Typography>
      <Grid container spacing={4} justify="center">
        {data.docs.map((snapshot, index) => {
          return <Item key={index.toString()} data={snapshot.data()} />
        })}
      </Grid>
    </div>
  )
}

export default History

const stl = makeStyles(theme => ({
  wrapper: {
    flex: 1,
    alignItems: "center",
  },
  container: {
    borderRadius: 5,
    margin: "5px 10px",
    textAlign: "center",
  },
  heading: {
    textAlign: "center",
    fontSize: 18,
    color: "gray",
    marginBottom: 20,
  },
  notice: {
    fontSize: 18,
    color: "#FFFFFF",
  },
  date: {
    fontSize: 11,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loading: {
    fontSize: 11,
    marginVertical: 5,
  },
  actionItem: {
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    margin: "0 5px",
  },
  icon: {
    color: "#158467",
    fontSize: 30,
  },
  pdf: {},
  download: {},
  iconWarning: {
    color: "#FFFFFF",
    fontSize: 25,
  },
}))
