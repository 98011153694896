import React from "react"
import { StaticQuery, graphql } from "gatsby"

// @MaterialUI
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

// @Local
import SEO from "../seo"
import GalleryList from "../gallery/galleryList"

const Page = () => (
  <Grid xs={12}>
    <SEO title="Multimedia" />
    <Typography
      className="page-title"
      variant="h1"
      color="textPrimary"
      align="center"
    >
      MULTIMEDIA
    </Typography>
    <StaticQuery
      query={queryContent}
      render={data => (
        <Grid className="wrapperItemsList">
          <GalleryList articles={data.allStrapiGallery.edges} />
        </Grid>
      )}
    />
  </Grid>
)

export default Page

const queryContent = graphql`
  query {
    allStrapiGallery(
      filter: { published: { eq: true } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          title
          fields {
            slug
          }
          images {
            url
            formats {
              medium {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`
