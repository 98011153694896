import React from "react"
import { navigate } from "gatsby"

// @Material UI
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Avatar from "@material-ui/core/Avatar"
import WorkIcon from "@material-ui/icons/Work"
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail"
import PhoneIcon from "@material-ui/icons/Phone"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import Button from "@material-ui/core/Button"

// @Firebase
import firebase from "gatsby-plugin-firebase"
// @Local & Auth.
import { logout, getUser, getUserExtra } from "../../utils/auth"
import SEO from "../seo"

const stl = makeStyles(theme => ({
  container: {
    justifyContent: "center",
    textAlign: "center",
    maxWidth: 400,
    margin: "0 auto",
  },
  card: {
    justifyContent: "center",
  },
  wrapperAvatar: {
    marginTop: 30,
    marginBottom: 30,
  },
  avatar: {
    height: 150,
    width: 150,
    borderRadius: 20,
    margin: "0 auto",
  },
  name: {
    fontSize: 25,
    textAlign: "center",
    fontWeight: 700,
    marginBottom: 40,
    letterSpacing: 2,
    color: "#365eab",
  },
  itemInfo: {
    display: "flex",
    alignItems: "center",
    margin: 20,
  },
  itemIcon: {
    marginRight: 40,
    color: "#365eab",
  },
  itemText: {
    color: "#5b5656",
    fontWeight: 300,
    fontSize: 16,
  },
  button: {
    marginTop: 50,
  },
}))

const Profile = () => {
  const classes = stl()
  const user = getUser()
  const userExtra = getUserExtra()
  const { displayName, email, photoURL } = user
  const { phone, ocupation } = userExtra

  return (
    <Grid xs={12} className={classes.container}>
      <SEO title={displayName ? displayName : "Perfil"} />
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.wrapperAvatar}>
            <Avatar
              sizes={"large"}
              alt={displayName}
              src={photoURL ? photoURL : null}
              className={classes.avatar}
            >
              {!photoURL ? displayName.charAt(0) : null}
            </Avatar>
          </div>
          <Typography className={classes.name}>{displayName}</Typography>
          <div className={classes.itemInfo}>
            <AlternateEmailIcon className={classes.itemIcon} />
            <Typography className={classes.itemText}>{email}</Typography>
          </div>
          {userExtra && ocupation ? (
            <div className={classes.itemInfo}>
              <WorkIcon className={classes.itemIcon} />
              <Typography className={classes.itemText}>{ocupation}</Typography>
            </div>
          ) : null}
          {userExtra && phone ? (
            <div className={classes.itemInfo}>
              <PhoneIcon className={classes.itemIcon} />
              <Typography className={classes.itemText}>{phone}</Typography>
            </div>
          ) : null}
        </CardContent>
      </Card>
      <Button
        variant="outlined"
        color="secondary"
        className={classes.button}
        onClick={event => {
          event.preventDefault()
          logout(firebase).then(() => navigate(`/app/login`))
        }}
        size={"large"}
        endIcon={<ExitToAppIcon />}
      >
        Cerrar sesión
      </Button>
    </Grid>
  )
}

export default Profile
