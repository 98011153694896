import React from "react"
import clsx from "clsx"

// @Material UI
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Avatar from "@material-ui/core/Avatar"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Skeleton from "@material-ui/lab/Skeleton"

// @Firebase
import firebase from "gatsby-plugin-firebase"

// @Local
import SEO from "../seo"
import TopButtons from "../singles/topButtons"
import BlockNews from "../singles/latestNews"
import { BirthdayButton } from "../actions/Actions"
import { getEditorialFormat } from "../../utils/date"

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  card: {
    textAlign: "center",
    height: "100%",
  },
  cardWrapper: {
    height: "100%",
    justifyContent: "center",
    display: "flex",
  },
  cardContent: {
    textAlign: "left",
    paddingLeft: 20,
  },
  name: {
    fontSize: 15,
    fontWeight: 500,
    color: "#5b5656",
    textTransform: "uppercase",
  },
  email: {
    fontSize: 12,
    color: "#5b5656",
    fontWeight: 300,
  },
  ocupation: {
    fontSize: 12,
    color: "#5b5656",
    fontWeight: 100,
  },
  phone: {
    fontSize: 12,
    color: "#d5392d",
    fontWeight: 300,
  },
  wrapperFilters: {
    justifyContent: "center",
  },
  activeFilter: {
    backgroundColor: "#365eab",
    color: "#FFF",
  },
  avatar: {
    width: 70,
    height: 70,
    margin: "0 auto",
    border: "solid",
    borderWidth: 3,
    borderColor: "#365eab",
  },
  today: {
    borderColor: "#95389e",
  },
})

const SkeletonItems = ({ num }) => {
  const classes = useStyles()
  let items = []
  for (let i = 1; i <= num; i++) {
    items.push(
      <Grid item xs={12} sm={10}>
        <Card className={classes.card}>
          <CardContent className={classes.cardWrapper}>
            <Grid container alignItems={"center"}>
              <Grid item xs={3}>
                <Skeleton variant="circle" width={70} height={70} />
              </Grid>
              <Grid item xs={9}>
                <Skeleton variant="rect" width={210} height={20} border={5} />
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={150} />
                <Skeleton variant="text" width={80} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }
  return items
}

const UserCard = ({ data, today }) => {
  const classes = useStyles()
  const todayPeriod = new Date()
  const period = `${todayPeriod.getFullYear()}-${data.birthday_month}`

  return (
    <Grid item xs={12} sm={10}>
      <Card className={classes.card}>
        <CardContent className={classes.cardWrapper}>
          <Grid container alignItems={"center"}>
            <Grid item xs={2}>
              {data._photoURL ? (
                <Avatar
                  sizes={"large"}
                  alt={data.name}
                  src={data._photoURL}
                  className={clsx(
                    classes.avatar,
                    today === data.birthday_day ? classes.today : null
                  )}
                />
              ) : (
                <Avatar
                  alt={data.name}
                  className={clsx(
                    classes.avatar,
                    today === data.birthday_day ? classes.today : null
                  )}
                >
                  {data.name.charAt(0) + data.lastname.charAt(0)}
                </Avatar>
              )}
            </Grid>
            <Grid item xs={8} className={classes.cardContent}>
              <Typography className={classes.name} color="textSecondary">
                {data.name} {data.lastname}
              </Typography>
              <Typography className={classes.ocupation} color="textSecondary">
                {data.ocupation} | {data.group}
              </Typography>
              <Typography color="textSecondary">
                <a href={`mailto:${data.email}`} className={classes.email}>
                  {data.email}
                </a>
              </Typography>
              <Typography className={classes.phone} color="textSecondary">
                {getEditorialFormat(data.birthday_day, data.birthday_month)}
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.cardContent}>
              <BirthdayButton
                id={data.email}
                title={data.email}
                type={"Birthday"}
                period={period}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

const List = () => {
  const [data, setData] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)
    if (!data) {
      firebase
        .firestore()
        .collection("DTVecolDirectory")
        .where("active", "==", "1")
        .where("birthday_month", "==", new Date().getUTCMonth() + 1)
        .orderBy("birthday_day")
        // .limit(15)
        .get()
        .then(snapshot => {
          if (snapshot.size > 0) {
            setData(snapshot.docs)
          }
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [data])

  if (loading) {
    return (
      <Grid
        container
        xs={12}
        spacing={2}
        alignItems={"stretch"}
        alignContent={"center"}
        style={{ justifyContent: "center" }}
      >
        <SkeletonItems num={5} />
      </Grid>
    )
  }

  return (
    <Grid
      container
      xs={12}
      spacing={2}
      alignItems={"stretch"}
      alignContent={"center"}
      style={{ justifyContent: "center" }}
    >
      {data && data.length > 0
        ? data.map((snapshot, index) => {
            return (
              <UserCard
                key={index}
                data={snapshot.data()}
                today={new Date().getDate()}
              />
            )
          })
        : null}
    </Grid>
  )
}

const Page = () => {
  return (
    <Grid xs={12}>
      <SEO title={"Cumpleaños"} />
      <Grid container spacing={3} className="page-detail highLight-detail">
        <Grid item xs={12} sm={7} lg={7} className="page-detail-content">
          <Typography
            className="page-title"
            variant="h1"
            color="textPrimary"
            align="center"
          >
            Cumpleaños
          </Typography>
          <List />
        </Grid>
        <Grid item xs={12} sm={5} lg={5} className="page-detail-sidebar">
          <div style={{ height: 100 }} />
          <TopButtons />
          <div style={{ height: 50 }} />
          <BlockNews />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Page
