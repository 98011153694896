import React from "react"
import clsx from "clsx"
import { StaticQuery, navigate, graphql } from "gatsby"

// @MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import ButtonBase from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

// Local Components.
import screenConvert from "../_misc/ScreenConvert"
import BlockNews from "../singles/latestNews"

function Card({ data, index }) {
  const classes = useStyles()
  let { link, screenname, extra, group } = data

  const pressed = async () => {
    if (link && link !== null && link !== "_none" && link !== "none") {
      window.open(link, "_blank")
    } else if (screenname) {
      var path = screenConvert[screenname]
      if (extra && extra.screenParams && extra.screenParams.id) {
        path = path.concat(`/${extra.screenParams.id}`)
      }
      navigate(path)
    }
  }

  if (group && group.name === "Public") {
    return null
  }

  if (screenname === "@screenGalleryList") {
    return (
      <Grid item xs={12} md={6} lg={6}>
        <BlockNews />
      </Grid>
    )
  }

  return (
    <Grid item xs={12} md={6} lg={6}>
      {data.background && data.background.publicURL ? (
        <ButtonBase
          focusRipple
          key={data.title}
          className={classes.image}
          focusVisibleClassName={classes.focusVisible}
          onClick={pressed}
          style={{
            width: "100%",
            borderRadius: 5,
            overflow: "hidden",
          }}
        >
          <span
            className={clsx(classes.imageSrc, "img-bg-cover-center")}
            style={{ backgroundImage: `url(${data.background.publicURL})` }}
          />
          <span className={classes.imageBackdrop} />
          <span className={classes.imageButton}>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
            >
              {data.title}
              <span className={classes.imageMarked} />
            </Typography>
          </span>
        </ButtonBase>
      ) : (
        <div style={{ height: "100%", width: "100%" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={pressed}
            style={{
              height: "100%",
              width: "100%",
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            {data.title}
          </Button>
        </div>
      )}
    </Grid>
  )
}

export default function Block() {
  return (
    <Grid container spacing={1}>
      <StaticQuery
        query={queryBannerButtons}
        render={data =>
          data.strapiCompHomeBtnImages.items.map((item, index) => (
            <Card data={item} index={index} />
          ))
        }
      />
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}))

// Queries Single components @STRAPI
const queryBannerButtons = graphql`
  query {
    strapiCompHomeBtnImages {
      items {
        id
        title
        screenname
        link
        background {
          publicURL
        }
        group {
          name
        }
        extra {
          screenParams {
            id
          }
        }
      }
    }
  }
`
