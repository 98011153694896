import React from "react"
import clsx from "clsx"

// @Gatsby
import { navigate } from "gatsby"

// @MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import EventIcon from "@material-ui/icons/Event"
import AddIcon from "@material-ui/icons/Add"

// @Local
import { getDateFormat } from "../../utils/date"

const EventCard = ({ article }) => {
  const classes = useStyles()
  return (
    <Card
      className={clsx(classes.card && "event-card content-card")}
      onClick={() => {
        navigate(`/contenido/eventos/${article.node.fields.slug}`)
      }}
    >
      <CardMedia
        className={classes.cardMedia}
        image={article.node.image.publicURL}
        title={article.node.title}
      />
      <CardContent className={classes.cardContent}>
        <Typography className={classes.titleCard}>
          {article.node.title}
        </Typography>
        <Grid
          container
          className={classes.wrapperDates}
          spacing={1}
          alignContent={"center"}
        >
          <Grid item xs={6} className={classes.itemDates}>
            <EventIcon className={classes.greenIcon} />
            {article.node.date_init && (
              <Typography>{getDateFormat(article.node.date_init)}</Typography>
            )}
          </Grid>
          <Grid item xs={6} className={classes.itemDates}>
            <EventIcon className={classes.redIcon} />
            {article.node.date_final && (
              <Typography>{getDateFormat(article.node.date_final)}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          alignContent={"center"}
          className={classes.wrapperPlace}
        >
          <LocationOnIcon className={classes.greenIcon} />
          {article.node.place && <Typography>{article.node.place}</Typography>}
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          size={"small"}
          variant="outlined"
          color="primary"
          endIcon={<AddIcon />}
        >
          Detalle de evento
        </Button>
      </CardActions>
    </Card>
  )
}

export default EventCard

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  titleCard: {
    fontWeight: 500,
    color: "#365eab",
    fontSize: 18,
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  wrapperPlace: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
    "& p": {
      fontWeight: 300,
      fontSize: 14,
      color: "#5b5656",
    },
  },
  wrapperDates: {
    justifyContent: "center",
    alignItems: "stretch",
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  itemDates: {
    "& p": {
      fontWeight: 300,
      fontSize: 14,
      color: "#5b5656",
    },
  },
  greenIcon: {
    fontSize: 30,
    color: "#5b5656",
  },
  redIcon: {
    fontSize: 30,
    color: "#ff8e8e",
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}))
