import React from "react"
import clsx from "clsx"

// @Gatsby
import { navigate } from "gatsby"

// @MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/Add"

const GalleryCard = ({ article }) => {
  const classes = useStyles()
  return (
    <Card
      className={clsx(classes.card, "gallery-card content-card")}
      onClick={() => {
        navigate(`/contenido/multimedia/${article.node.fields.slug}`)
      }}
    >
      <CardMedia
        className={classes.cardMedia}
        image={
          article.node.images[0].formats.medium
            ? article.node.images[0].formats.medium.publicURL
            : article.node.images[0].url
        }
        title={article.node.title}
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h5" component="h2">
          {article.node.title}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size={"small"}
          variant="outlined"
          color="primary"
          endIcon={<AddIcon />}
        >
          Detalle de Galería
        </Button>
      </CardActions>
    </Card>
  )
}

export default GalleryCard

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}))
