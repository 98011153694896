import React from "react"
import clsx from "clsx"

// @Gatsby
import { navigate } from "gatsby"

// @MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import EventIcon from "@material-ui/icons/Event"

// @Local
import { getDateFormat } from "../../utils/date"

const JobCard = ({ article }) => {
  const classes = useStyles()
  return (
    <Card
      className={clsx(classes.card && "job-card content-card")}
      onClick={() => {
        navigate(`/contenido/vacantes/${article.node.fields.slug}`)
      }}
    >
      <CardMedia
        className={classes.cardMedia}
        image={article.node.image.publicURL}
        title={article.node.title}
      />
      <CardContent className={classes.cardContent}>
        <Typography className={classes.titleCard}>
          {article.node.title}
        </Typography>
        {article.node.date ? (
          <Grid
            item
            xs={12}
            alignContent={"center"}
            className={classes.wrapperPlace}
          >
            <EventIcon className={classes.greyIcon} />
            <Typography>{getDateFormat(article.node.date)}</Typography>
          </Grid>
        ) : null}
        {article.node.place ? (
          <Grid
            item
            xs={12}
            alignContent={"center"}
            className={classes.wrapperPlace}
          >
            <LocationOnIcon className={classes.greyIcon} />
            <Typography>{article.node.place}</Typography>
          </Grid>
        ) : null}
      </CardContent>
      <CardActions>
        <Button
          size={"small"}
          variant="outlined"
          color="primary"
          endIcon={<ChevronRightIcon />}
        >
          Ver convocatoria
        </Button>
      </CardActions>
    </Card>
  )
}

export default JobCard

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  wrapperPlace: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
    "& p": {
      fontWeight: 300,
      fontSize: 14,
      color: "#5b5656",
      marginLeft: 20,
    },
  },
  greyIcon: {
    fontSize: 30,
    color: "#5b5656",
  },
  titleCard: {
    fontWeight: 500,
    color: "#365eab",
    fontSize: 18,
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
}))
