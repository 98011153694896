import React from "react"

// @MaterialUI
import Grid from "@material-ui/core/Grid"

// @LocalComponents.
import GalleryCard from "./galleryCard"

const Items = ({ articles }) => {
  return (
    <Grid container spacing={4} xs={12}>
      {articles.map((article, i) => {
        return (
          <Grid item key={i} xs={12} sm={6} md={4}>
            <GalleryCard article={article} position={i} key={`article__${i}`} />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Items
