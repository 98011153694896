import React from "react"
import clsx from "clsx"

// @Gatsby
import { navigate } from "gatsby"

// @MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import { getDateFormat } from "../../utils/date"

const NewsCard = ({ article }) => {
  const classes = useStyles()
  let { extlink } = article.node

  return (
    <Card
      className={clsx(
        classes.card,
        "news-card content-card",
        extlink ? "ext-link-ready" : null
      )}
      onClick={() => {
        !extlink
          ? navigate(`/contenido/actualidad/${article.node.fields.slug}`)
          : window.open(extlink, "_blank")
      }}
    >
      <CardMedia
        className={classes.cardMedia}
        image={article.node.image.publicURL}
        title={article.node.title}
      />
      <CardContent className={classes.cardContent}>
        {extlink ? (
          <div className="ext-icon-wrapper">
            <OpenInNewIcon />
          </div>
        ) : null}
        <div className="card-date">
          {article.node.date && <p>{getDateFormat(article.node.date)}</p>}
        </div>
        <Typography gutterBottom variant="h5" component="h2">
          {article.node.title}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size={"small"}
          variant="outlined"
          color="primary"
          endIcon={extlink ? <OpenInNewIcon /> : <ChevronRightIcon />}
        >
          {extlink ? "Ir a noticia externa" : "Ver noticia"}
        </Button>
      </CardActions>
    </Card>
  )
}

export default NewsCard

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}))
