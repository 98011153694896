import React from "react"
import { Router } from "@reach/router"
import Login from "../components/DTLogin"
import Default from "../components/DTLogin"
import DefaultRoot from "../components/DTLogin"
import PrivateRoute from "../components/PrivateRoute"
import Home from "../components/PvtPages/Home"
import Actualidad from "../components/PvtPages/Actualidad"
import Vacantes from "../components/PvtPages/Vacantes"
import Eventos from "../components/PvtPages/Eventos"
import Multimedia from "../components/PvtPages/Multimedia"
import Cumpleanos from "../components/PvtPages/Cumpleanos"
import Directorio from "../components/PvtPages/Directorio"
import Profile from "../components/PvtPages/Profile"
import Drive from "../components/PvtPages/Drive"
import DriveExt from "../components/PvtPages/DriveExt"
import ManageForms from "../components/_misc/ManageForms"

const App = () => {
  return (
    <Router>
      <DefaultRoot path="/" />
      <Default path="/app" />
      <Login path="/app/login" />
      <PrivateRoute path="/app/perfil" component={Profile} />
      <PrivateRoute
        path="/app/home"
        component={Home}
        section={"Panel de control"}
      />
      <PrivateRoute
        path="/app/actualidad"
        component={Actualidad}
        section={"Actualidad"}
      />
      <PrivateRoute
        path="/app/vacantes"
        component={Vacantes}
        section="Vacantes"
      />
      <PrivateRoute path="/app/eventos" component={Eventos} section="Eventos" />
      <PrivateRoute
        path="/app/multimedia"
        component={Multimedia}
        section="Multimedia"
      />
      <PrivateRoute
        path="/app/cumpleanos"
        component={Cumpleanos}
        section={"Cumpleaños"}
      />
      <PrivateRoute
        path="/app/directorio"
        component={Directorio}
        section={"Directorio"}
      />
      <PrivateRoute
        path="/app/solicitudes/:typeId"
        component={ManageForms}
        section={"Solicitudes y certificados"}
      />
      <PrivateRoute
        path="/app/nomina"
        component={Drive}
        section={"Carpeta nómina"}
      />
      <PrivateRoute path="/app/contenido/pagina/:id" />
      <DriveExt path="/app/vecol/sync-nomina/:dtVecolCodeNom" />
    </Router>
  )
}

export default App
