import React from "react"
import { StaticQuery, graphql } from "gatsby"

// @MaterialUI
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

// @Local
import SEO from "../seo"
import JobList from "../jobs/jobList"

const Page = () => (
  <Grid xs={12}>
    <SEO title="Vacantes" />
    <Typography
      className="page-title"
      variant="h1"
      color="textPrimary"
      align="center"
    >
      VACANTES
    </Typography>
    <StaticQuery
      query={queryContent}
      render={data => (
        <Grid className="wrapperItemsList">
          <JobList articles={data.allStrapiJob.edges} />
        </Grid>
      )}
    />
  </Grid>
)

export default Page

const queryContent = graphql`
  query {
    allStrapiJob(filter: { published: { eq: true } }) {
      edges {
        node {
          id
          title
          strapiId
          content
          date
          place
          fields {
            slug
          }
          image {
            publicURL
          }
        }
      }
    }
  }
`
