import React, { useState, useEffect } from "react"
import clsx from "clsx"
import { useLocation } from "@reach/router"
import MDReactComponent from "markdown-react-js"
import DateFnsUtils from "@date-io/date-fns"
import esLocale from "date-fns/locale/es"

// @Formik
import { Formik, Form } from "formik"
import * as Yup from "yup"

// @Material UI
import { makeStyles } from "@material-ui/core/styles"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Alert from "@material-ui/lab/Alert"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

// @animations
import Lottie from "react-lottie"
import animationData from "../../lotties/notFound"
import animationLoader from "../../lotties/vecolLoader"

// @Firebase
import firebase from "gatsby-plugin-firebase"

// @Local
import { isLoggedIn, getUser } from "../../utils/auth"
import { SuccessSimple } from "../_misc/Messages"
import { getDateRegularES } from "../../utils/date"
import SEO from "../seo"
import TopButtons from "../singles/topButtons"
import BlockNews from "../singles/latestNews"
import DTVecolJCertH from "./DTVecolJCertH"
import DTVecolNCertH from "./DTVecolNCertH"
import DTVecolIRCertH from "./DTVecolIRCertH"

const stl = makeStyles(theme => ({
  wrapperHeadingText: {
    textAlign: "center",
    marginTop: 50,
    marginBottom: 100,
  },
  headingText: {
    color: "#5b5656",
    fontWeight: 300,
    fontSize: 25,
  },
  form: {
    width: "100%",
    textAlign: "center",
  },
  button: {
    margin: "5% 0",
  },
  date: {
    width: "100%",
    borderWidth: 1,
    border: "solid",
    borderRadius: 10,
    fontSize: 13,
    padding: 5,
    textAlign: "center",
    color: "#FFF",
  },
  pendingCancelButton: {
    backgroundColor: "#d5392d",
    margin: "10px 0",
  },
  pendingCancelText: {
    color: "#FFF",
    fontWeight: 900,
  },
  summary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FEFEFE",
    margin: "10px 0",
    borderRadius: 5,
    alignSelf: "center",
    width: "100%",
    padding: 3,
  },
  summaryText: {
    fontWeight: 700,
    fontSize: 13,
    color: "#5b5656",
    width: "30%",
  },
  summaryArrow: {
    fontSize: 35,
    color: "#5b5656",
    width: "30%",
  },
  btnSelected: {
    background: "green",
  },
}))

const lottieNotFound = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const lottieLoader = {
  loop: true,
  autoplay: true,
  animationData: animationLoader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const formSchema = Yup.object().shape({
  text: Yup.string().required("Acompaña tu solicitud con un comentario"),
})

const formSchemaVacation = Yup.object().shape({
  initialDate: Yup.date().required(),
  finalDate: Yup.date("Fecha es obligatoria")
    .required("Fecha es obligatoria")
    .when(
      "initialDate",
      (initialDate, formSchemaVacation) =>
        initialDate &&
        formSchemaVacation.min(
          initialDate,
          `Fecha final no debe ser inferior a la fecha inicial`
        )
    ),
})

const General = props => {
  const classes = stl()
  const { type, extraProps } = props
  const [status, setStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [snap, setSnap] = useState(null)

  useEffect(() => {
    setLoading(true)
    const subscriber = firebase
      .firestore()
      .collection("SpecialActionsCertificates")
      .where("user_uid", "==", getUser().uid)
      .where("_state", "in", ["SENT", "NOT-PROCESS"])
      .where("type", "==", type)
      .onSnapshot(querySnapshot => {
        if (querySnapshot && querySnapshot.docs) {
          querySnapshot.forEach(documentSnapshot => {
            if (documentSnapshot && documentSnapshot.id) {
              setStatus(documentSnapshot.data()._state)
              setSnap(documentSnapshot)
            }
          })
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
    // Unsubscribe from events when no longer in use
    return () => subscriber()
  }, [])

  const removeRequest = async () => {
    if (snap.id) {
      await firebase
        .firestore()
        .collection("SpecialActionsCertificates")
        .doc(snap.id)
        .delete()
        .then(() => {
          setStatus(false)
          setSnap(null)
        })
    }
  }

  const addRequest = async text => {
    var data = {
      user_uid: getUser().uid ? getUser().uid : "anonymous",
      user_mail: getUser().email ? getUser().email : "anonymous",
      type: type,
      text: text,
      _origin: "intranet",
      _created: firebase.firestore.Timestamp.fromDate(new Date()),
      _state: "NOT-PROCESS",
    }
    await firebase
      .firestore()
      .collection("SpecialActionsCertificates")
      .doc()
      .set(data)
  }

  if (loading) {
    return <Lottie options={lottieLoader} height={200} width={200} />
  }

  return (
    <Grid container xs={12}>
      {status && status === "SENT" && snap && (
        <SuccessSimple
          heading={"¡SOLICITUD ENVIADA!"}
          text={
            "Se ha enviado la petición al área encargada. Te responderán a tu email lo más pronto posible."
          }
        >
          <div className={classes.date}>
            {`Fecha de solicitud: ${getDateRegularES(
              snap.data()._created.toDate()
            )}`}
          </div>
        </SuccessSimple>
      )}
      {status && status !== "SENT" && snap && (
        <SuccessSimple
          heading={"EN PROCESO"}
          bgColor="#f0a500"
          text={"Estamos en proceso de notificar al área encargada"}
          buttonText={"Cancelar Solicitud"}
          onClick={() => {
            removeRequest()
          }}
        >
          <div className={classes.date}>
            {`Fecha de solicitud: ${getDateRegularES(
              snap.data()._created.toDate()
            )}`}
          </div>
        </SuccessSimple>
      )}
      {!status ? (
        <Formik
          initialValues={{ text: "" }}
          validationSchema={formSchema}
          onSubmit={values => {
            addRequest(values.text)
          }}
        >
          {({ errors, values, touched, handleChange, handleSubmit }) => (
            <Form className={classes.form}>
              <Grid item xs={12} className={classes.textareaWrapper}>
                <TextField
                  placeholder={
                    extraProps?.placeholder ?? "Ej. Con destino a ..."
                  }
                  id="additional-info"
                  label="Información adicional"
                  name="text"
                  value={values.text}
                  className={classes.textarea}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                />
                {errors.text && touched.text ? (
                  <Alert className={classes.textareaWarning} severity="warning">
                    {errors.text}
                  </Alert>
                ) : null}
              </Grid>
              <Grid item xs={12} className={classes.textareaWarning}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  size={"large"}
                  type="submit"
                >
                  Enviar
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : null}
    </Grid>
  )
}

const Vacation = props => {
  const classes = stl()
  const [status, setStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  const [snap, setSnap] = useState(null)

  useEffect(() => {
    setLoading(true)
    const subscriber = firebase
      .firestore()
      .collection("SpecialActionsVacationRequest")
      .where("_state", "in", ["SENT", "NOT-PROCESS"])
      .where("user_uid", "==", getUser().uid)
      .onSnapshot(querySnapshot => {
        if (
          querySnapshot &&
          querySnapshot.docs.length &&
          querySnapshot.docs.length > 0
        ) {
          querySnapshot.forEach(documentSnapshot => {
            if (documentSnapshot.exists && documentSnapshot.id) {
              setStatus(documentSnapshot.data()._state)
              setSnap(documentSnapshot)
            }
            setLoading(false)
          })
        } else {
          setLoading(false)
        }
      })
    // Unsubscribe from events when no longer in use
    return () => subscriber()
  }, [])

  const vacationRequestAdd = async values => {
    const currentUser = getUser()
    await firebase
      .firestore()
      .collection("SpecialActionsVacationRequest")
      .doc()
      .set({
        user_uid: currentUser.uid ? currentUser.uid : "anonymous",
        user_mail: currentUser.email ? currentUser.email : "anonymous",
        initialFull: values.initialDate.getTime(),
        initialYear: values.initialDate.getFullYear(),
        initialMonth: values.initialDate.getUTCMonth() + 1,
        initialDay: values.initialDate.getUTCDate(),
        finalFull: values.finalDate.getTime(),
        finalYear: values.finalDate.getFullYear(),
        finalMonth: values.finalDate.getUTCMonth() + 1,
        finalDay: values.finalDate.getUTCDate(),
        _origin: "intranet",
        _created: firebase.firestore.Timestamp.fromDate(new Date()),
        _state: "NOT-PROCESS",
      })
  }

  const vacationRequestRemove = async () => {
    await firebase
      .firestore()
      .collection("SpecialActionsVacationRequest")
      .doc(snap.id)
      .delete()
      .then(() => {
        setStatus(false)
        setSnap(null)
      })
  }

  if (loading) {
    return <Lottie options={lottieLoader} height={200} width={200} />
  }

  var DateInitialString = null
  var DateFinalString = null
  if (status && snap) {
    DateInitialString = getDateRegularES(new Date(snap.data().initialFull))
    DateFinalString = getDateRegularES(new Date(snap.data().finalFull))
  }

  return (
    <Grid container xs={12}>
      {status && status === "SENT" && snap && (
        <SuccessSimple
          heading={"¡SOLICITUD ENVIADA!"}
          text={
            "Se ha enviado la petición al área encargada. Te responderán a tu email lo más pronto posible."
          }
        >
          <div className={classes.summary}>
            <span className={classes.summaryText}>{DateInitialString}</span>
            <ChevronRightIcon className={classes.summaryArrow} />
            <span className={classes.summaryText}>{DateFinalString}</span>
          </div>
          <div className={classes.date}>
            {`Fecha de solicitud: ${getDateRegularES(
              snap.data()._created.toDate()
            )}`}
          </div>
        </SuccessSimple>
      )}
      {status && status !== "SENT" && snap && (
        <SuccessSimple
          bgColor="#f0a500"
          warning={true}
          heading={"EN PROCESO"}
          text={
            "Ya has iniciado una solicitud de vacaciones la cual está en proceso de notificación."
          }
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              vacationRequestRemove()
            }}
            className={classes.pendingCancelButton}
          >
            <div className={classes.pendingCancelText}>
              {props.buttonText ? props.buttonText : "Cancelar Solicitud"}
            </div>
          </Button>
          <div className={classes.summary}>
            <span className={classes.summaryText}>{DateInitialString}</span>
            <ChevronRightIcon className={classes.summaryArrow} />
            <span className={classes.summaryText}>{DateFinalString}</span>
          </div>
          <div className={classes.date}>
            {`Fecha de solicitud: ${getDateRegularES(
              snap.data()._created.toDate()
            )}`}
          </div>
        </SuccessSimple>
      )}
      {!status ? (
        <Formik
          initialValues={{ initialDate: new Date(), finalDate: null }}
          validationSchema={formSchemaVacation}
          onSubmit={values => vacationRequestAdd(values)}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className={classes.form}>
              <Grid container justify="space-around">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      id="date-picker-dialog"
                      name="initialDate"
                      label="Desde:"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={values.initialDate}
                      onChange={value => setFieldValue("initialDate", value)}
                      minDate={new Date()}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                    {errors.initialDate && touched.initialDate ? (
                      <Alert
                        className={classes.textareaWarning}
                        severity="warning"
                      >
                        {errors.initialDate}
                      </Alert>
                    ) : null}
                  </Grid>
                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      id="date-picker-dialog"
                      name="finalDate"
                      label="Hasta:"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={values.finalDate}
                      minDate={values.initialDate}
                      onChange={value => setFieldValue("finalDate", value)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                    {errors.finalDate && touched.finalDate ? (
                      <Alert
                        className={classes.textareaWarning}
                        severity="warning"
                      >
                        {errors.finalDate}
                      </Alert>
                    ) : null}
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} className={classes.textareaWarning}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  size={"large"}
                  type="submit"
                >
                  Finalizar Solicitud
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : null}
    </Grid>
  )
}

const JCert = ({ form }) => {
  const classes = stl()
  const { options } = form
  const [selected, setSelected] = useState(null)
  const [loading, setLoading] = useState(false)

  const confirm = async () => {
    setLoading(true)
    var data = {
      user_uid: getUser().uid ? getUser().uid : "anonymous",
      user_mail: getUser().email ? getUser().email : "anonymous",
      type: "jCert",
      codeCert: selected,
      text: options[selected - 1].name,
      _origin: "intranet",
      _created: firebase.firestore.Timestamp.fromDate(new Date()),
      _state: "NOT-PROCESS",
      response: {
        url: null,
        message: "pending",
      },
    }
    await firebase
      .firestore()
      .collection("SpecialActionsCertificates")
      .doc()
      .set(data)
      .then(result => {
        setLoading(false)
        setSelected(null)
      })
  }

  return (
    <div>
      <Grid container spacing={2} justify={"center"}>
        {options.map((item, index) => {
          return (
            <Grid item xs={12} sm={12} lg={6} style={{ textAlign: "center" }}>
              <Button
                style={{ margin: 5, minWidth: 200 }}
                variant="contained"
                color="primary"
                onClick={() =>
                  setSelected(selected !== item.id ? item.id : null)
                }
                size={"large"}
              >
                {item.name}
              </Button>
            </Grid>
          )
        })}
        {selected && (
          <div style={{ margin: "2rem 0" }}>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => confirm()}
              size={"large"}
              disabled={loading}
            >
              {`Generar certificado ${options[selected - 1].name}`}
            </Button>
          </div>
        )}
      </Grid>
      <DTVecolJCertH />
    </div>
  )
}

const NCert = ({ form }) => {
  const classes = stl()
  const { years, months } = form
  const [selectedY, setSelectedY] = useState(null)
  const [selectedM, setSelectedM] = useState(null)
  const [loading, setLoading] = useState(false)

  const confirm = async () => {
    setLoading(true)
    var data = {
      user_uid: getUser().uid ? getUser().uid : "anonymous",
      user_mail: getUser().email ? getUser().email : "anonymous",
      type: "nCert",
      prima: false,
      year: selectedY,
      month: selectedM,
      text: `${selectedM}/${selectedY}`,
      _origin: "intranet",
      _created: firebase.firestore.Timestamp.fromDate(new Date()),
      _state: "NOT-PROCESS",
      response: {
        url: null,
        message: "pending",
      },
    }
    await firebase
      .firestore()
      .collection("SpecialActionsCertificates")
      .doc()
      .set(data)
      .then(result => {
        setLoading(false)
        setSelectedY(null)
        setSelectedM(null)
      })
  }

  return (
    <div>
      <Grid container spacing={2} justify={"center"}>
        {years.map((item, index) => {
          return (
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <Button
                style={{ margin: 5, minWidth: 150 }}
                variant="contained"
                color="primary"
                onClick={() =>
                  setSelectedY(selectedY !== item.id ? item.id : null)
                }
                className={selectedY === item.id ? classes.btnSelected : null}
                size={"large"}
              >
                {item.name}
              </Button>
            </Grid>
          )
        })}
        {months.map((item, index) => {
          return (
            <Grid item xs={6} sm={4} style={{ textAlign: "center" }}>
              <Button
                style={{ margin: 5, minWidth: 120, fontSize: 12 }}
                variant="contained"
                color="primary"
                onClick={() =>
                  setSelectedM(selectedM !== item.id ? item.id : null)
                }
                className={selectedM === item.id ? classes.btnSelected : null}
                size={"large"}
              >
                {item.name}
              </Button>
            </Grid>
          )
        })}
        {selectedY && selectedM && (
          <div style={{ margin: "2rem 0" }}>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => confirm()}
              size={"large"}
              disabled={loading}
            >
              {`Generar certificado`}
            </Button>
          </div>
        )}
      </Grid>
      <DTVecolNCertH />
    </div>
  )
}

const IrCert = ({ form }) => {
  const classes = stl()
  const { years } = form
  const [selectedY, setSelectedY] = useState(null)
  // const [selectedM, setSelectedM] = useState(null);
  const [loading, setLoading] = useState(false)

  const confirm = async () => {
    setLoading(true)
    var data = {
      user_uid: getUser().uid ? getUser().uid : "anonymous",
      user_mail: getUser().email ? getUser().email : "anonymous",
      type: "irCert",
      year: selectedY,
      text: `${selectedY}`,
      _origin: "intranet",
      _created: firebase.firestore.Timestamp.fromDate(new Date()),
      _state: "NOT-PROCESS",
      response: {
        url: null,
        message: "pending",
      },
    }
    await firebase
      .firestore()
      .collection("SpecialActionsCertificates")
      .doc()
      .set(data)
      .then(result => {
        setLoading(false)
        setSelectedY(null)
      })
  }

  return (
    <div>
      <Grid container spacing={2} justify={"center"}>
        {years.map((item, index) => {
          return (
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <Button
                style={{ margin: 5, minWidth: 150 }}
                variant="contained"
                color="primary"
                onClick={() =>
                  setSelectedY(selectedY !== item.id ? item.id : null)
                }
                className={selectedY === item.id ? classes.btnSelected : null}
                size={"large"}
              >
                {item.name}
              </Button>
            </Grid>
          )
        })}
        {selectedY && (
          <div style={{ margin: "2rem 0", width: "100%", textAlign: "center" }}>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => confirm()}
              size={"large"}
              disabled={loading}
            >
              {`Generar certificado ${selectedY}`}
            </Button>
          </div>
        )}
      </Grid>
      <DTVecolIRCertH />
    </div>
  )
}

const Manage = ({ typeId }) => {
  const classes = stl()
  const location = useLocation()
  let { params } = location.state
  let { type, heading, title } = params

  if (type !== typeId || !isLoggedIn()) {
    return <Lottie options={lottieNotFound} height={400} width={400} />
  }

  var formElements = null
  if (type && type === "jCert") {
    formElements = <JCert {...params} />
  } else if (type && type === "nCert") {
    formElements = <NCert {...params} />
  } else if (type && type === "irCert") {
    formElements = <IrCert {...params} />
  } else if (type && type === "job_vacation") {
    formElements = <Vacation {...params} />
  } else {
    formElements = <General {...params} />
  }

  return (
    <Grid
      container
      alignContent={"center"}
      alignItems={"center"}
      justifyContent={"center"}
      className={classes.container}
    >
      <SEO title={title ? title : "Solicitudes y certificados"} />
      <Grid container spacing={3} className="page-detail basic-detail">
        <Grid item xs={12} sm={7} lg={7} className="page-detail-content">
          <Typography
            className="page-title"
            variant="h1"
            color="textPrimary"
            align="center"
          >
            {heading.title ? heading.title : null}
          </Typography>
          <div className={clsx(classes.wrapperHeadingText)}>
            {heading.text && (
              <MDReactComponent
                text={heading.text ? heading.text : null}
                className={classes.headingText}
              />
            )}
          </div>
          {formElements}
        </Grid>
        <Grid item xs={12} sm={5} lg={5} className="page-detail-sidebar">
          <div style={{ height: 100 }} />
          <TopButtons />
          <div style={{ height: 50 }} />
          <BlockNews />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Manage
