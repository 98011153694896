import React from "react"
import { StaticQuery, graphql } from "gatsby"

// @MaterialUI
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

// @Local
import SEO from "../seo"
import NewsList from "../news/newsList"

const Page = () => (
  <Grid xs={12}>
    <SEO title="Actualidad" />
    <Typography
      className="page-title"
      variant="h1"
      color="textPrimary"
      align="center"
    >
      ACTUALIDAD
    </Typography>
    <StaticQuery
      query={queryAllNews}
      render={data => (
        <Grid className="wrapperItemsList">
          <NewsList articles={data.allStrapiArticle.edges} />
        </Grid>
      )}
    />
  </Grid>
)

export default Page

const queryAllNews = graphql`
  query {
    allStrapiArticle(
      filter: { article_type: { name: { eq: "News" } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          strapiId
          title
          slug
          date
          extlink
          image {
            publicURL
          }
          article_type {
            name
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
