import React from "react"
import { StaticQuery, graphql } from "gatsby"
import MDReactComponent from "markdown-react-js"

// @MaterialUI
import { makeStyles } from "@material-ui/core/styles"

// @LocalComponents
import Title from "../Title"
import { isLoggedIn, getUserExtra } from "../../utils/auth"

import { connect } from "react-redux"
import { blancoAction } from "../../actions/blancoAction"
import { rojoAction } from "../../actions/rojoAction"

const useStyles = makeStyles(theme => ({
  markdown: {
    "& a": {
      fontWeight: 700,
      color: "#FFF",
    },
    "& a strong": {
      fontWeight: 700,
      color: "#FFF",
    },
  },
}))

// Queries Single components @STRAPI
const queryWelcome = graphql`
  query {
    strapiWelcomeMessage(active: { eq: true }) {
      title
      message
      extra {
        intranetMessage
      }
    }
  }
`

function WelcomeMessage(props) {
  const classes = useStyles()
  const userData = getUserExtra()

  return (
    <div className={"dt-block welcome-message"}>
      <StaticQuery
        query={queryWelcome}
        render={data => (
          <div className="dt-block-content" style={{ textAlign: "center" }}>
            {isLoggedIn() ? (
              <Title>
                {data.strapiWelcomeMessage.title.replace(
                  "@name",
                  userData.name
                )}
              </Title>
            ) : (
              <Title>
                {data.strapiWelcomeMessage.title.replace("@name", null)}
              </Title>
            )}
            <MDReactComponent
              text={data.strapiWelcomeMessage.extra.intranetMessage || data.strapiWelcomeMessage.message}
              className={classes.markdown}
            />
            <h3>{props.content}</h3>
          </div>
        )}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  ...state,
})

const mapDispatchToProps = dispatch => ({
  blancoAction: () => dispatch(blancoAction),
  rojoAction: () => dispatch(rojoAction),
})

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeMessage)
